import React, { Component } from "react";

import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";
import {
  subscribeForEvents,
  unsubscribeForEvents
} from "../projects/ProjectActions";
import { signOut } from "../auth/AuthActions";

class Dashboard extends Component {
  state = {
    officeId: "CA999",
    series: "G",
    ticket: "100"
  };

  handleSubscribeForEvents = e => {
    this.props.subscribeForEvents(
      this.state.officeId,
      this.state.series,
      this.state.ticket
    );
    e.preventDefault();
  };

  handleUnsubscribeForEvents = e => {
    this.props.unsubscribeForEvents();
    e.preventDefault();
  };

  render() {
    const { auth, offices } = this.props;

    if (!auth.uid) {
      return <Redirect to="/auth/phone" />;
    }

    console.info("UID: ", auth.uid);

    if (offices === undefined) {
      return <div>Loading</div>;
    } else {
      const serving = offices.CA999.serving;
      return (
        <div>
          <header>
            <h1>DMV Monkey</h1>
          </header>
          <div className=" container">
            <div className="row">
              <div className="col s12 m6">{serving.G}</div>

              <div>
                <button
                  className="btn btn-primary"
                  onClick={this.handleSubscribeForEvents}
                >
                  Subscribe
                </button>

                <button
                  className="btn btn-primary"
                  onClick={this.handleUnsubscribeForEvents}
                >
                  Unsubscribe
                </button>

                <button className="btn btn-primary" onClick={signOut}>
                  Sign Out
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    firebase: state.firebase,
    offices: state.firestore.data.offices
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    subscribeForEvents: (officeId, series, ticket) =>
      dispatch(subscribeForEvents(officeId, series, ticket, props)),
    unsubscribeForEvents: () => dispatch(unsubscribeForEvents(props)),
    signOut: () => dispatch(signOut(props))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    {
      collection: "offices",
      doc: "CA999"
    }
  ])
)(Dashboard);
