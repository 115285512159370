import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Dashboard from "./dashboard/Dashboard";

import CreateProject from "./projects/CreateProject";
import ProjectDetails from "./projects/ProjectDetails";

import ViewSignInPhone from "./auth/ViewSignInPhone";
import ViewSignInCode from "./auth/ViewSignInCode";

import PrivacyPage from "./Misc/PrivacyPage";
import TermsPage from "./Misc/TermsPage";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Switch>
            <Route exact path="/" component={Dashboard} />

            <Route path="/projects/create" component={CreateProject} />
            <Route path="/projects/:id" component={ProjectDetails} />

            <Route path="/auth/phone" component={ViewSignInPhone} />
            <Route path="/auth/code" component={ViewSignInCode} />

            <Route path="/legal/terms" component={TermsPage} />
            <Route path="/legal/privacy" component={PrivacyPage} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
