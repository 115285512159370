import React from "react";

const TermsPage = () => {
  return (
    <div className="container">
      <h4>Privacy Policy</h4>
      <sub>Updated: Oct 15, 2019</sub>
      <p>This Application collects some Personal Data from its Users.</p>
      <p className="flow-text">
        All your data belongs to you and we don't resell it ever. We purely
        monetize from your subscription and nothing else.
      </p>

      <h5>Registration and authentication</h5>
      <p className="flow-text">
        We use your phone number to create your account. We store your phone
        number, but nothing else about you. We don't collect names or addresses.
      </p>
      <p className="flow-text">
        Your phone number will be removed if you choose to delete your account.
      </p>

      <h5>Location Information</h5>
      <p className="flow-text">
        With your permission, we read the location of your device and use it
        purely to determine your nearest DMV offices from which you will need to
        pick from.
      </p>
      <p className="flow-text">
        Your location is never sent or saved on our servers neither is it
        resold.
      </p>

      <h5>Your subscription</h5>
      <p className="flow-text">
        When subscribing, we send and save your ticket number along with the DMV
        you received your ticket from and for the duration of that day and up
        until your ticket is up, we sent notifications to your phone.
      </p>

      <h5>Other information</h5>
      <p className="flow-text">
        For accounting purposes we maintain all payment transactions of all
        users for the calendar year. Once the tax year is over, we purge that
        data from our servers.
      </p>
      <p className="flow-text">
        Under your account we save all payments and notifications and it is
        available to you via the application.
      </p>

      <h5>3rd party services</h5>
      <p className="flow-text">
        We use the following services:
        <ul>
          <li>Google Firebase (Firestore, Auth)</li>
          <li>Twillio SMS</li>
        </ul>
      </p>
      <p className="flow-text">
        We have actively disabled Google's FirebaseAnalytics capability in order
        to further maintain privacy.
      </p>
    </div>
  );
};

export default TermsPage;
