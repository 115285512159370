import React, { Component } from "react";
import { connect } from "react-redux";
import { signInWithCode } from "./AuthActions";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";

import "./AuthForms.css";

class ViewSignInCode extends Component {
  state = {
    smsCode: ""
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    this.props.signInWithCode(this.state.smsCode);
    e.preventDefault();
  };

  handleWrongNumber = () => {
    localStorage.setItem("AUTH_PHONE_NUMBER", "");
    localStorage.setItem("AUTH_VERIFICATION_ID", "");
    this.props.history.push("/auth/phone");
  };

  render() {
    const { auth, authError } = this.props;
    const verificationId = localStorage.getItem("AUTH_VERIFICATION_ID");
    const phoneNumber = localStorage.getItem("AUTH_PHONE_NUMBER");

    if (
      verificationId === undefined ||
      verificationId === null ||
      verificationId === ""
    ) {
      return <Redirect to="/auth/phone" />;
    }

    if (auth.uid) {
      return <Redirect to="/" />;
    }

    return (
      <form className="form-signin" onSubmit={this.handleSubmit}>
        <img
          className="mb-4 rounded-lg"
          src="/images/icons/icon-192x192.png"
          alt=""
          width="72"
          height="72"
        />

        <h1 className="h3 mb-3 font-weight-normal">Verify its your phone</h1>

        <p>We send a code to your phone number</p>

        <fieldset>
          <label htmlFor="smsCode" className="sr-only">
            Phone Number (+1 123 456 7890)
          </label>
          <input
            type="number"
            id="smsCode"
            className="form-control"
            placeholder={`Code sent to ${phoneNumber}`}
            autoComplete="tel"
            onChange={this.handleChange}
            required
            autoFocus
          />
        </fieldset>

        <button
          className="btn btn-lg btn-primary btn-block mt-4 mb-4"
          type="submit"
        >
          Sign In
        </button>

        {authError ? <div className="invalid-feedback">{authError}</div> : null}

        <button
          className="btn btn-lg btn-link btn-block mt-4 mb-4"
          onClick={this.handleWrongNumber}
        >
          I entered the wrong phone number.
        </button>
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    signInWithCode: smsCode => dispatch(signInWithCode(smsCode, props))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ViewSignInCode));
