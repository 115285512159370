import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// Replace this with your own config details
const firebaseConfig = {
  apiKey: "AIzaSyAskmsJ8fULPqvPGd8Z9BXf8OWWnThbwWk",
  authDomain: "dmv-monkey.firebaseapp.com",
  databaseURL: "https://dmv-monkey.firebaseio.com",
  projectId: "dmv-monkey",
  storageBucket: "dmv-monkey.appspot.com",
  messagingSenderId: "423858888757",
  appId: "1:423858888757:web:a313571b37232e5fd3117c"
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

firebase.auth().useDeviceLanguage();

firebase
  .auth()
  .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  .then(() => {
    console.log("AUTH Persistence set to LOCAL");
  });

export default firebase;
