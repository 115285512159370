export function createProject(project) {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const profile = getState().firebase.profile;
    const authorId = getState().firebase.auth.uid;
    firestore
      .collection("projects")
      .add({
        ...project,
        authorFirstName: profile.firstName,
        authorLastName: profile.lastName,
        authorId: authorId,
        createdAt: new Date()
      })
      .then(() => {
        dispatch({ type: "CREATE_PROJECT_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "CREATE_PROJECT_ERROR" }, err);
      });
  };
}

export async function getCurrentTicketServed(officeId) {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    if (officeId === undefined) {
      return {};
    }

    const firestore = getFirestore();

    firestore
      .collection("offices")
      .doc(officeId)
      .get()
      .then(doc => {
        if (doc.exists) {
          const office = doc.data();
          console.log("Document data:", doc.data());
          return office.get("serving");
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      });
  };
}

export async function addSubscription(office, series, ticket) {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    if (!firebase.auth().currentUser) {
      return alert("Not authorized");
    }

    return firestore
      .collection("subscriptions")
      .doc(office)
      .collection()
      .doc(firebase.auth().currentUser.uid)
      .set({
        office: office,
        series: series,
        ticket: ticket
      });
  };
}

const getTodayKey = () => {
  const d = new Date();

  const month = d.getMonth() + 1;
  const day = d.getDate();
  const year = d.getFullYear();

  return `${year}.${month}.${day}`;
};

export const subscribeForEvents = (officeId, series, ticket, props) => {
  console.info("subscribeForEvents");

  return (dispatch, getState, { getFirebase, getFirestore }) => {
    console.info("subscribe #2");

    const firebase = getFirebase();
    const firestore = getFirestore();

    const user = firebase.auth().currentUser;

    if (user === undefined) {
      console.log("    Missing user");
      return;
    }

    const uid = user.uid;
    console.info("Subscriber ", uid);

    const todayKey = getTodayKey();

    const subscriptionDoc = firestore
      .collection("subscriptions")
      .doc(officeId)
      .collection(todayKey)
      .doc(uid);

    subscriptionDoc
      .set({
        uid: uid,
        officeId: officeId,
        series: series,
        ticket: ticket,
        added: firestore.FieldValue.serverTimestamp()
      })
      .then(() => {
        localStorage.setItem("SUBSCRIPTION_OFFICE_ID", officeId);
        console.log("[+] Subscription added");
      });
  };
};

export const unsubscribeForEvents = props => {
  console.info("subscribeForEvents");

  return (dispatch, getState, { getFirebase, getFirestore }) => {
    console.info("subscribe #2");

    const officeId = localStorage.getItem("SUBSCRIPTION_OFFICE_ID");

    if (officeId === undefined || officeId === null || officeId === "") {
      console.info("Nothing to unsubscribe from");
      return;
    }

    console.info("Unsubscribe from officeId", officeId);

    const firebase = getFirebase();
    const firestore = getFirestore();

    const user = firebase.auth().currentUser;

    if (user === undefined || user === null || user === "") {
      console.log("    Missing user");
      return;
    }

    const uid = user.uid;
    console.info("Subscriber ", uid);

    const todayKey = getTodayKey();

    const subscriptionDoc = firestore
      .collection("subscriptions")
      .doc(officeId)
      .collection(todayKey)
      .doc(uid);

    subscriptionDoc.delete().then(() => {
      localStorage.removeItem("SUBSCRIPTION_OFFICE_ID");
      console.log("[-] Subscription removed");
    });
  };
};
