const initState = {
  authError: null
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "SIGNIN_ERROR":
      console.log("SIGNIN_ERROR", action.err.message);
      return {
        ...state,
        authError: action.err.message //"Sign In Error"
      };

    case "SIGNIN_SMS_SENT":
      console.log("SIGNIN_SMS_SENT");
      return {
        ...state,
        authError: null
      };

    case "SIGNIN_SUCCESS":
      console.log("SIGNIN_SUCCESS");
      return {
        ...state,
        authError: null
      };

    case "SIGNOUT_SUCCESS":
      console.log("SIGNOUT_SUCCESS");
      return state;

    default:
      return state;
  }
};

export default authReducer;
