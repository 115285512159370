export const signInWithPhoneNumber = (phoneNumber, props) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const appVerifier = window.recaptchaVerifier;

    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then(confirmationResult => {
        console.info("[AUTH] signInWithPhoneNumber completed");
        localStorage.setItem("AUTH_PHONE_NUMBER", phoneNumber);

        localStorage.setItem(
          "AUTH_VERIFICATION_ID",
          confirmationResult.verificationId
        );

        console.log(
          "    Received verificationId",
          confirmationResult.verificationId
        );

        dispatch({ type: "SIGNIN_SMS_SENT" });

        console.info("[AUTH] redirecting to /auth/code");

        props.history.push("/auth/code");
      })
      .catch(err => {
        dispatch({ type: "SIGNIN_ERROR", err });
      });
  };
};

export const signInWithCode = (code, props) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    const verificationId = localStorage.getItem("AUTH_VERIFICATION_ID");

    var credential = firebase.auth.PhoneAuthProvider.credential(
      verificationId,
      code
    );

    firebase
      .auth()
      .signInWithCredential(credential)
      .then(result => {
        localStorage.removeItem("AUTH_VERIFICATION_ID");

        var user = result.user;

        console.log("user", user);
        console.log("user.uid", user.uid);

        dispatch({ type: "SIGNIN_SUCCESS" });

        props.history.push(`/`);
      })
      .catch(err => {
        localStorage.removeItem("AUTH_VERIFICATION_ID");

        dispatch({ type: "SIGNIN_ERROR", err });
      });
  };
};

export const signOut = props => {
  console.log("AuthActions.signOut()");
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    console.info("INSIDE 1");

    firebase
      .auth()
      .signOut()
      .then(() => {
        console.info("INSIDE 2");

        dispatch({ type: "SIGNOUT_SUCCESS" });
        props.history.push("/auth/phone");
      })
      .catch(err => {
        console.info("INSIDE 3");

        console.error(err);
      });
  };
};
