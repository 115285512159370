import React, { Component } from "react";
import { connect } from "react-redux";
import { signInWithPhoneNumber } from "./AuthActions";
import { Redirect } from "react-router-dom";
import firebase from "firebase/app";
import { withRouter } from "react-router-dom";

import "./AuthForms.css";

class ViewSignInPhone extends Component {
  componentDidMount() {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: function(response) {
          console.info("recaptcha callback");
        }
      }
    );

    window.recaptchaVerifier.render().then(function(widgetId) {
      window.recaptchaWidgetId = widgetId;
    });

    localStorage.setItem("AUTH_PHONE_NUMBER", "");
  }

  state = {
    phoneNumber: ""
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    this.props.signInWithPhoneNumber(this.state.phoneNumber);
    e.preventDefault();
  };

  render() {
    const { auth, authError } = this.props;
    const verificationId = localStorage.getItem("AUTH_VERIFICATION_ID");

    if (auth.uid) {
      return <Redirect to="/" />;
    }

    if (verificationId) {
      return <Redirect to="/auth/code" />;
    }

    return (
      <form className="form-signin" onSubmit={this.handleSubmit}>
        <img
          className="mb-4 rounded-lg"
          src="/images/icons/icon-192x192.png"
          alt=""
          width="72"
          height="72"
        />
        <h1 className="h3 mb-3 font-weight-normal">Sign In With Your Phone</h1>

        <fieldset className="mb-4">
          <label htmlFor="phoneNumber" className="sr-only">
            Phone Number (+1 123 456 7890)
          </label>
          <input
            type="phonenumber"
            id="phoneNumber"
            className="form-control"
            placeholder="Phone Number (+1 123.456.7890)"
            autoComplete="tel"
            onChange={this.handleChange}
            required
            autoFocus
          />
        </fieldset>

        <div id="recaptcha-container"></div>

        <button className="btn btn-lg btn-primary btn-block mt-4" type="submit">
          Request Code
        </button>

        {authError ? <div className="invalid-feedback">{authError}</div> : null}
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    signInWithPhoneNumber: phoneNumber =>
      dispatch(signInWithPhoneNumber(phoneNumber, props))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ViewSignInPhone));
